const MainStacks = () => {
    return (
        <div
            id="main_stacks"
            className="flex flex-col h-auto bg-gradient-to-br sm:bg-gradient-to-r from-mojito-1 to-mojito-2 text-white p-8 font-poppins">
            <ul className="flex flex-wrap gap-2 justify-center">
                <li>
                    <div className="flex flex-col p-8 text-center w-56 sm:w-60">
                        <img
                            className="w-56 sm:w-fit"
                            src={process.env.PUBLIC_URL + '/mobile_development.png'} id="game_dev"/>
                        <h2 className="font-bold text-xl">Web/Mobile Development</h2>
                        <p>Developing user-friendly mobile and responsive web apps with good UI UX.</p>
                    </div>
                </li>
                <li>
                    <div className="flex flex-col p-8 text-center w-56 sm:w-60">
                        <img
                            className="w-56 sm:w-full"
                            src={process.env.PUBLIC_URL + '/project_management.png'} id="game_dev"/>
                        <h2 className="font-bold text-xl">Project Management</h2>
                        <p>Project and IT team management using Agile methodology.</p>
                    </div>
                </li>
                <li>
                    <div className="flex flex-col p-8 text-center w-56 sm:w-60">
                        <img
                            className="w-56 sm:w-full"
                            src={process.env.PUBLIC_URL + '/game_development.png'} id="game_dev"/>
                        <h2 className="font-bold text-xl">Game Development</h2>
                        <p>Development of attractive video games playable on mobile or desktop.</p>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default MainStacks
