const HomeView = () => {
    return (
        <div id="home" className="flex flex-col sm:flex-row h-screen w-auto align-middle">
            <div
                className="flex flex-col
                justify-center items-center
                bg-gradient-to-br from-mango-1
                to-mango-2 text-slate-800 h-1/2 w-full sm:h-full sm:w-1/2 p-2">
                <div className="flex flex-col gap-2">
                    <h1>Hi, I am</h1>
                    <h1 className="text-4xl font-bold font-poppins">ANDONIAINA Nomenjanahary</h1>
                    <p className="text-lg font-fira">Skilled IT engineer with a passion for new technology. Always looking to learn and develop my career.</p>
                    <button
                        className="bg-mango-2 w-1/2 rounded-full shadow p-2 hover:bg-orange-500 font-poppins font-bold">
                        Download my CV
                    </button>
                </div>
            </div>
            <div className="flex flex-col justify-center items-center text-black h-1/2 w-full sm:h-full sm:w-1/2">
                <img
                    className="object-cover overflow-scroll w-full grayscale"
                    src={process.env.PUBLIC_URL + 'profile_pic.jpg'} alt="profile_pic" />
            </div>
        </div>
    )
}

export default HomeView
