import {EnvelopeIcon, PhoneIcon} from "@heroicons/react/24/solid";


const Contacts = () => {
    return (
        <div
            className="h-auto flex flex-col gap-2 p-8 bg-gradient-to-bl from-mango-1 to-mango-2 font-poppins text-white"
            id="contacts">
            <h1 className="text-xl font-bold">Contact Me!</h1>
            <div className="flex flex-row gap-2">
                <EnvelopeIcon className="h-6 w-6 text-white"/>
                <h1>Email: ando01niaina@gmail.com</h1>
            </div>
            <div className="flex flex-row gap-2">
                <PhoneIcon className="h-6 w-6 text-white" />
                <h1>Tel: +261 33 30 086 51</h1>
            </div>
            <h1>Facebook: Andoniaina Nomenjanahary</h1>
            <span className="h-3" />
            <p className="w-full sm:text-center">Andoniaina Nomenjanahary (c) 2024</p>
        </div>
    )
}

export default Contacts
