const TechnicalSkills = () => {
    return (
        <div
            className='flex flex-col gap-2 py-8'
            id='technical_skills'>
            <h1
                className='text-xl font-poppins font-bold text-gray-800 w-full text-center'
            >Technical Skills</h1>
            <div
                className="flex flex-wrap gap-2 sm:gap-4 w-screen justify-center">
                <img className='h-14 sm:h-20 sm:grayscale sm:hover:grayscale-0' src='/pl_logos/Python.svg' alt='Python Logo'/>
                <img className='h-14 sm:h-20 sm:grayscale sm:hover:grayscale-0' src='/pl_logos/CPlusPlus.svg' alt='CPlusPlus'/>
                <img className='h-14 sm:h-20 sm:grayscale sm:hover:grayscale-0' src='/pl_logos/JS.svg' alt='JS'/>
                <img className='h-14 sm:h-20 sm:grayscale sm:hover:grayscale-0' src='/pl_logos/TypeScript.svg' alt='TypeScript'/>
                <img className='h-14 sm:h-20 sm:grayscale sm:hover:grayscale-0' src='/pl_logos/Kotlin.svg' alt='Kotlin'/>
            </div>
        </div>
    )
}

export default TechnicalSkills
