import { MapPinIcon } from "@heroicons/react/24/solid";
import { CalendarIcon } from "@heroicons/react/24/solid";

const WorkExperience = () => {
    return (
        <div
            id="work_experience"
            className="flex flex-col bg-gradient-to-br from-gray-700 to-gray-500 text-white p-8 font-poppins">
            <h1 className="flex justify-center text-center text-xl w-full items-center align-middle font-bold">Work Experiences</h1>
            <ul className="flex flex-wrap justify-center gap-2">
                <li className="sm:w-1/2">
                    <div className="flex flex-col gap-2 text-center p-8">
                        <div className="flex flex-row gap-2 justify-center text-slate-300">
                            <CalendarIcon className="h-6 w-6"/>
                            <p>Jul 2024 - Aug 2024</p>
                        </div>
                        <h1 className="text-xl font-bold text-gray-100">Backend Developer & DevOps</h1>
                        <h4 className="font-bold flex flex-row place-content-center">
                            <MapPinIcon className="h-6 w-6 text-slate-100"/>
                            Sania Emergence
                        </h4>
                        <p>Developing a fintech startup's backend application & maintaining VPS server and cron
                            jobs.</p>
                        <ul className="flex flex-row gap-2 justify-center">
                            <li className="text-xs px-2 py-1 bg-slate-50 text-gray-800 rounded-full">Python</li>
                            <li className="text-xs px-2 py-1 bg-slate-50 text-gray-800 rounded-full">Flask</li>
                            <li className="text-xs px-2 py-1 bg-slate-50 text-gray-800 rounded-full">CI/CD</li>
                        </ul>
                    </div>
                </li>
                <li className="sm:w-1/2">
                    <div className="flex flex-col gap-2 text-center p-8">
                        <div className="flex flex-row gap-2 justify-center text-slate-300">
                            <CalendarIcon className="h-6 w-6"/>
                            <p>Apr 2021 - Apr 2024</p>
                        </div>
                        <h1 className="text-xl font-bold text-gray-100">Python/C++ Developer</h1>
                        <h4 className="font-bold flex flex-row place-content-center">
                            <MapPinIcon className="h-6 w-6 text-slate-100"/>
                            Futurmap Data
                        </h4>
                        <p>Developing CAO software's functionality and data treatment scripts.</p>
                        <ul className="flex flex-row gap-2 justify-center">
                            <li className="text-xs px-2 py-1 bg-slate-50 text-gray-800 rounded-full">C++</li>
                            <li className="text-xs px-2 py-1 bg-slate-50 text-gray-800 rounded-full">Python</li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default WorkExperience
