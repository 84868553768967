import { TrophyIcon } from "@heroicons/react/24/solid";

const Projects = () => {
    return (
        <div id="projects">
            <h1 className="flex justify-center text-center text-xl items-center font-bold align-middle p-8 text-slate-600 font-poppins">Featured
                Projects</h1>
            <ul className="flex flex-wrap gap-2 justify-center">
                <li className="border border-gray-200 rounded-lg bg-slate-100
                    sm:hover:drop-shadow-2xl drop-shadow-lg w-full sm:w-72 text-center m-2 sm:hover:bg-slate-300">
                    <img
                        alt='lokorush_banner'
                        className="object-cover w-auto rounded-t-lg"
                        src={process.env.PUBLIC_URL + 'lokorush_fb_banner.png'} id="game_dev"/>
                    <div className="flex flex-col gap-2 p-8 font-poppins">
                        <h1 className="font-bold text-xl">Loko Rush</h1>
                        <p className="text-justify">Developing an indie game during GameLoad GameJam 2024 event,
                            including creating the game UI UX.</p>
                        <div className='flex flex-row gap-2'>
                            <TrophyIcon className="h-5 w-5 text-yellow-500" /> 3rd Place
                        </div>
                        <ul className="flex flex-row gap-2 justify-center">
                            <li className="text-xs px-2 py-1 bg-slate-600 text-white rounded-full">Adobe XD</li>
                            <li className="text-xs px-2 py-1 bg-slate-600 text-white rounded-full">Unity</li>
                        </ul>
                        <button
                            className="bg-mojito-1 text-white rounded-full py-1">
                            <a className="text-sm text-wrap" href="https://www.facebook.com/lokorush">View</a>
                        </button>
                    </div>
                </li>
            </ul>
        </div>
    )
}

export default Projects;
