import logo from './logo.svg';
import './App.css';
import HomeView from './views/HomeView';
import MainStacks from './views/MainStacks';
import WorkExperience from './views/WorkExperience';
import Projects from './views/Projects';
import TechnicalSkills from './views/TechnicalSkills';
import Contacts from './views/Contacts';

function App() {
  return (
    <div className="App">
      <HomeView />
      <MainStacks />
      <WorkExperience />
      <Projects />
      <TechnicalSkills />
      <Contacts />
    </div>
  );
}

export default App;
